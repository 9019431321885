//Main Outer Box
*{
    margin: 0px;
    padding: 0px;
}
.main-box
{
//     padding-top: 100px;
    padding-left: 20px;
    position: relative;
    top: -80px;
    border-radius: 10px;
}
@media only screen and (max-width: 640px) {
    .main-box{
       padding: 0px 10px;
    }
}

//CSS For the Character Information
.EditImg
{
    
    object-fit: cover;
    width: 180px;
    height: 180px;
    border-radius: 10px;
   
}
@media only screen and (max-width: 640px) {
    .EditImg {
        object-fit: cover;
        width: 120px;
        height: 120px;
        border-radius: 10px;
    }
}
.Char-Info
{
    
    display: flex;
    flex-direction: row;
    gap: 15px;
   
}

.outer-info
{
    gap: 14px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen and (max-width: 640px) {
    .outer-info{
        width: 100%;
    }
}

.Per-Info
{
    
    display: flex;
    flex-direction: column;
    gap: 1px;
    
}

.Per-Info h1
{

    color: #16181D;
    font-weight: 600;
    height: fit-content;
    margin: 0px;
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 640px) {
    .Per-Info h1 {
        font-size: 18px; /* Font size for screens wider than 640px */
    }
}
.Per-Info p
{
    color: #434751;
    font-weight: 500;
    margin: 0px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 640px) {
    .Per-Info p {
        font-size: 13px; /* Font size for screens wider than 640px */
    }
}
.Edit-Detail
{
    width: fit-content;
    padding: 3px 9px;
    background-color: transparent;
    border-radius: 18px;
    border: 1px solid #b3b3b3;
    font-family: Arial, Helvetica, sans-serif;
    color: #2B2F36;
    font-weight: 500;
    font-size: 14px;

}

@media only screen and (max-width: 640px) {
    .Edit-Detail{
        padding: 2px 8px;
        font-size: 9px;
    }
}

//Options CSS

.Options 
{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;
   
}


.optionsActive
{
    margin-top: 10px;
    border: none;
    padding: 0px;
    background-color: rgba(179, 179, 179, 0.247);
    padding: 6px;
    font-weight: 600;
    color:black;
    border-radius: 12px;
}
@media only screen and (max-width: 640px) {
    .notActive{  
        font-size: 12px;
    }
}
@media only screen and (max-width: 640px) {
    .optionsActive{  
        font-size: 12px;
        font-weight: 700;
    }
}

.notActive
{
    margin-top: 10px;
    border:none;
    background-color: transparent;
    padding: 6px;
    font-weight: 600;
    color:black;

}
.optionsOuterActive
{
    text-align: start;
    margin-top: 10px;
    border: none;
    background-color: rgb(255, 255, 255);
    padding: 6px;
    font-weight: 600;
    color:rgb(0, 80, 184);
    width: 160px;
    border-bottom: 2px solid rgb(0, 80, 184);
    font-size: 14px;
}
.notActiveOuter
{
    font-size: 14px;
    text-align: start;
    padding-left: 10px;
    margin-top: 10px;
    border:none;
    background-color: transparent;
    padding: 6px;
    font-weight: 600;
    color:black;
    width: 160px;
    border-bottom: 2px solid  transparent;

}




