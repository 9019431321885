.outerPersonality
{
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    width: 75%;
    padding: 0px 10px;
    position: absolute;
    padding-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
    .outerPersonality{
        width: 80%;
    }
}
@media only screen and (max-width: 800px) {
    .outerPersonality{
        width: 94%;
    }
}

@media only screen and (max-width: 640px) {
    .outerPersonality{
        width: 94%;
    }
}


.outerPersonality h1
{
   color: #16181D;
   font-size: 16px;
   padding: 10px;
   border-bottom: 1px solid rgba(207, 207, 207, 0.397);
   margin-bottom: 3%;
}

@media only screen and (max-width: 520px) {
    .outerPersonality h1{
       margin-bottom: 5%;
    }
}
.innerMood ol
{
  list-style-type: none;
  display: flex;
   flex-direction: row;
   width: 100%;
   justify-content: space-between;
   
}

.innerMood
{
    border-bottom: 1px solid rgba(207, 207, 207, 0.397);
    padding-bottom: 30px;
    padding-top: 10px;
}
.Mood h1
{
    border: none;
    padding: 0px;
}
@media only screen and (max-width: 640px) {
    .Mood h1{
        font-size: 14px;
        font-weight: 700;
    }
}

.Personality h1
{
    
    border: none;
    padding: 0px;
    
}

@media only screen and (max-width: 640px) {
    .Personality h1{
        font-size: 14px;
        font-weight: 700;
    }
}
.Personality
{
    width: 100%;
    border-left: 1px solid rgba(207, 207, 207, 0.397);
    padding-left: 20px;
    
}

@media only screen and (max-width: 520px) {
    .Personality{
border: none;
padding-left: 0px;
    }
}
.Mood
{
    
    width: 100%;
    padding-right: 12px;
    
}


.character
{
    display: flex;
    flex-direction: row;
    padding: 0px 20px ;
    padding-top: 20px;
    
}


@media only screen and (max-width: 640px) {
    .character{
        padding: 0px;
    }
}
@media only screen and (max-width: 520px) {
    .character{
        display: flex;
        flex-direction: column;
    }
}


.InputRange
{
    width: 100%;
    height: 2px;
    accent-color: #804AF0;
 
}



li
{
    color: #434751;
    font-weight: 600;
    font-size: 13px;
}

@media only screen and (max-width: 640px) {
    li{
        color: #434751;
        font-weight: 600;
        font-size: 11px;
    }
}


.Outer-InputTrait
{
    position: relative;
    width: 100%;
    // padding: 0px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgba(207, 207, 207, 0.733);
    border-radius: 6px;
    min-height: 56px;
    height: fit-content;
    justify-items: center;
    align-items: center;
    z-index: 1;
    padding-top: 10px;
}
.Outer-InputTrait:hover
{
    border: 1px solid rgb(0, 0, 0);

}
.Outer-InputTrait:focus
{
    border: 1px solid rgb(0, 105, 190);

}
.InputTrait
{
 min-width: 30px;
 width: 0px;
 flex-grow: 1;
 -webkit-box-flex: 1;
    text-overflow: ellipsis;
    opacity: 1;
 height: 36px;
 border: none;
 padding-left: 10px;
}
 
.tag-container
{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    height: fit-content;
    align-items: center;
    justify-items: center;
    padding: 6px 2px;
}

.tag{
    height: fit-content;
    background-color: rgba(189, 189, 189, 0.308);
    padding:  2px 6px;
    color: black;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    gap: 4px;
}



.close-Icon
{
    border: none;
    background-color: rgba(156, 156, 156, 0.363);
    color: white;
    border-radius: 16px;
    cursor: pointer;
}


#Character-Trait
{
    position: absolute;
    background-color: white;
    font-size: 14px;
    font-weight: 600;
    color: black;
    top: -12px;
    left: 10px;

}


.order
{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-bottom: 20px;
    padding-top: 10px;

}

.li
{
    padding: 4px 10px;
    border-radius: 16px;
    background-color: rgba(179, 179, 179, 0.247);
    border: none;
    font-weight: 600;
}

.libtn
{    border: none;
    font-weight: 600;
    background-color: white;
}



.outerPersonality small
{
    color: red;
    font-weight: 600;
    font-size: 12px;
}