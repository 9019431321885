.Outer-Basic
{
    width: 70%;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 30px;
    gap: 90px;
}

@media only screen and (max-width: 640px) {
    .Outer-Basic{
        width: 95%;
    }
}


.Motivation
{

    padding-left: 20px;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 640px) {
    .Motivation{
       padding-left: 10px;
    }
}

.Flaw
{
    
    padding-left: 20px;
    width: 100%;
    height: 100%;   
    font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 640px) {
    .Flaw{
       padding-left: 10px;
    }
}
.Motivation h1
{
    color: #16181D;
    font-size: 14px;
    font-weight: 600;
}
.Flaw h1
{
    color: #16181D;
    font-size: 14px;
    font-weight: 600;
}


.MotivationText{
  
    padding: 10px;
    border: 1px solid rgba(158, 158, 158, 0.356);
    width: 100%;
    resize: none;
    min-height: 140px;
    overflow: hidden;
    border-radius: 6px;
    height: fit-content;
  }
.MotivationText:hover{
  
    border: 1px solid rgb(3, 3, 3);
  }
.MotivationText:focus{
  
    border: 2px solid rgb(0, 42, 228);

  }
  .MotivationText::placeholder
{
    color: #a3a3a3;
}

.Motivation p
{
    font-size: 14px;
    color: #434751;
}