.chat-modal {
	.title {
		padding: 7px 1.5rem;
	}
	.chat-body {
		padding: 0 0 10px;
		height: calc(100vh - 290px) !important;
		overflow-y: auto !important;
		.msgInner {
			padding-bottom: 1px;
		}
		.msgMain {
			height: 100%;
			// height: 380px;
			overflow-y: auto;
		}
		.sendChatOuter {
			display: flex;
			justify-content: flex-end;
			align-items: start !important;
			margin: 10px 5px;
			& .send-msg {
				flex-wrap: wrap;
				max-width: 70%;
			}
			& .send-msg-texts {
				background: #2e73ec;
				border-radius: 12px 12px 0px 12px;
				color: white;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				margin: 0 0 2px;
				overflow-wrap: break-word;
				padding: 6px 15px 6px 12px;
				@media only screen and (max-width: 600px) {
					font-size: 12px;
				}
			}
			.msgUserImgsender {
				height: 36px !important;
				width: 36px !important;
				border-radius: 50%;
				margin-top: 0px !important;
				@media only screen and (max-width: 600px) {
					// height: 44px;
					// width: 44px;
					// margin-top: 21px;
				}
			}
		}
		.receiveChatOuter {
			display: flex;
			margin: 10px 5px;
			align-items: start;
			& .Recieve-msg {
				flex-wrap: wrap;
				max-width: 70%;
				padding: 5px 0 0 5px;
			}

			& .Recieve-msg-texts {
				background: #f8f8fa;
				border-radius: 12px 12px 12px 0px;
				color: black;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				margin: 0 0 2px;
				overflow-wrap: break-word;
				padding: 6px 12px;
				@media only screen and (max-width: 600px) {
					font-size: 12px;
				}
			}
			.msgUserImg {
				height: 36px !important;
				width: 36px !important;
				border-radius: 50%;
				margin-top: 5px !important;
				// @media only screen and (max-width: 600px) {
				//   height: 44px;
				//   width: 44px;
				//   margin-top: 21px;
				// }
			}
		}
	}
}

.bg-lightgrey {
	background-color: #f0f0f0 !important;
}
.bg-lightblue {
	background-color: #0fa7c6 !important;
}
.sound-wave {
	width: 100%; /* Full width to stretch across the container */
	height: 50px;
	border-radius: 15px; /* Rounded edges for modern look */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
