.table {
	.avatar-group .avatar {
		margin-right: 4px;
	}
	.badge i {
		margin-right: 10px;
	}
}
/////
.custom-card .card {
	overflow: hidden;
	position: relative;
	border-radius: 16px;
	border: none;
}

// .custom-card .card:hover {
//   // transform: scale(1.05);
// }
.custom-card:hover {
	opacity: 0.9;
	transition: opacity 0.3s ease;
	background-color: rgba(105, 98, 98, 0.041);
}

.card-image {
	border-radius: 16px;
}
.card-image:hover {
	overflow: hidden;
	position: relative;
	transition: transform 0.3s ease;
	transform: scale(1.05);
	cursor: pointer;
}

.card-body-overlay {
	position: absolute;
	height: 100%;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	left: 0;
	right: 0;
	opacity: 0;
	transition: opacity 0.3s ease;
	background-color: rgba(0, 0, 0, 0.397);
}

.card-body-overlay h5,
.card-body-overlay .card-options {
	margin: 0;
	visibility: hidden;
}

.custom-card .card:hover .card-body-overlay {
	opacity: 1;
	z-index: 4;
}

.custom-card .card:hover .card-body-overlay h5,
.custom-card .card:hover .card-body-overlay .card-options {
	visibility: visible;
}

.custom-card .card-options button {
	margin: 5px;
}

.card-options {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.threeBtn {
	border-radius: 18px;
	width: 90px;
}

.threeBtn:hover {
	background-color: black;
}
.cursor-pointer{
	cursor: pointer;
}