
.InputPersonal::placeholder
{
    color: rgb(160, 159, 159);
}


  
  .tag-containerKC {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 6px 2px;
    align-items: start;
    justify-items: start;
    min-height: 160px;
  }
  
  .line-numbers {
    overflow: auto;
    background-color: #F4F4F5;
    padding: 4px;
    height: 100%;
  }
  
  .line-number {
    height: 100%;
    // padding: 3px 8px ;
   
  
  }
  
  .InputPersonal {
  
    padding: 0px 10px;
    border: none;
    width: 100%;
    resize: none;
    min-height: 150px;
    overflow: hidden;
  }


  // CSS For Document.Js

  .DocumentImg
  {
    margin-top: 30px;   
    border-radius: 10px;
    // aspect-ratio: 1.5;
    width: 100%;
  
  }

  .inputformModal
  {
    width: fit-content;
  }


  @media only screen and (max-width: 640px) {
    .inputformModal{
       width: 100%;
    }
}


// Document Component CSS


.container {
  // background-color: #f2f2f2;
  padding: 30px;
  border-radius: 5px;
  text-align: center;
}

.container h1 {
  margin-bottom: 20px;
}

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 8px;
}

.drop-zone p {
  margin-bottom: 20px;
}

.drop-zone or {
  font-size: 12px;
  color: #ccc;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-zone .browse-button {
  background-color: #0064c2;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.drop-zone .browse-button:hover {
  background-color: #002397;
}
.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;

}

@media only screen and (max-width: 640px) {
  .file-info{
    display: flex;
  flex-direction: column;
  gap: 6px;
  }
}

.file-info span,
.file-info select {
  margin-right: 10px;
}

.file-info select {
  padding: 5px;
}

select option
{
  width: fit-content;
}


.hidden-file-input {
  display: none;

}

.browse-button {
  display: inline-block;
  background-color: #333; /* Customize the button's appearance */
  color: #fff;
  padding: 10px 20px;
  border: none;
  // cursor: pointer;
  /* Add more styling as needed */
}

p span
{
  color: #525F7F;
  font-weight: 600;
}

.file-info p 
{
  margin-bottom: 0px;
}